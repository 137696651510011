import { BUILD_ID } from "../../analytics";
import { mockBookingAvailability } from "../../mock/schedulingLinks.mock.data";
import {
  BookingAvailabilityDto,
  BookingAvailabilityTimeDto,
  SchedulingLinkMeetingRequestDto,
} from "../../mock/schedulingLinks.mock.types";
import { Override } from "../../types";
import { dateToStr, getDurationString, MILLISECONDS_PER_MINUTE } from "../../utils/dates";
import { dtoToEvent, Event, eventToDto } from "../Events";
import { nullable, TransformDomain } from "../types";
import { ThinPerson, TimePolicy } from "../Users";
import {
  CreateSchedulingLinkRequest as CreateSchedulingLinkRequestDto,
  IconType as SchedulingLinkIconTypeDto,
  PatchSchedulingLinkRequest as PatchSchedulingLinkRequestDto,
  ReclaimApi,
  SchedulingLink as SchedulingLinkDto,
  SchedulingPriority as SchedulingLinkPriorityDto,
  TimePolicyType as TimePolicyTypeDto,
} from "./scheduling-links-client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export type SchedulingLinkIconType = `${SchedulingLinkIconTypeDto}`;
export type SchedulingLinkPriority = `${SchedulingLinkPriorityDto}`;
export type TimePolicyType = `${TimePolicyTypeDto}`;

/**
 * SchedulingLink
 */

export type SchedulingLink = Readonly<
  Override<
    SchedulingLinkDto,
    {
      id: string;
      startDate: Date | undefined;
      endDate: Date | undefined;
      organizer: ThinPerson;
      timePolicyType: TimePolicyType;
      oneOffPolicy?: TimePolicy;
      iconType: SchedulingLinkIconType;
    }
  >
>;

export type CreateSchedulingLinkRequest = Override<
  CreateSchedulingLinkRequestDto,
  {
    startDate?: Date;
    endDate?: Date;
    timePolicyType: TimePolicyType;
    oneOffPolicy?: TimePolicy;
    iconType: SchedulingLinkIconType;
    priority: SchedulingLinkPriority;
  }
>;

export type PatchSchedulingLinkRequest = Override<
  PatchSchedulingLinkRequestDto,
  {
    startDate?: Date | null;
    endDate?: Date | null;
    timePolicyType?: TimePolicyType;
    oneOffPolicy?: TimePolicy | null;
    iconType: SchedulingLinkIconType;
    priority: SchedulingLinkPriority;
  }
>;

export const dtoToSchedulingLink = (dto: SchedulingLinkDto): SchedulingLink => ({
  ...dto,
  startDate: dto.startDate ? new Date(dto.startDate) : undefined,
  endDate: dto.endDate ? new Date(dto.endDate) : undefined,
  timePolicyType: dto.timePolicyType as TimePolicyType,
  organizer: dto.organizer as ThinPerson,
});

export const createSchedulingLinkRequestToDto = (
  link: CreateSchedulingLinkRequest
): CreateSchedulingLinkRequestDto => ({
  ...link,
  timePolicyType: link.timePolicyType as TimePolicyTypeDto,
  startDate: nullable(link.startDate, dateToStr),
  endDate: nullable(link.endDate, dateToStr),
  priority: link.priority as SchedulingLinkPriorityDto,
  iconType: link.iconType as SchedulingLinkIconTypeDto,
});

export const patchSchedulingLinkRequestToDto = (link: PatchSchedulingLinkRequest): PatchSchedulingLinkRequestDto => ({
  ...link,
  timePolicyType: link.timePolicyType as TimePolicyTypeDto,
  startDate: nullable(link.startDate, dateToStr),
  endDate: nullable(link.endDate, dateToStr),
  priority: link.priority as SchedulingLinkPriorityDto,
  iconType: link.iconType as SchedulingLinkIconTypeDto,
});

/**
 * BookingAvailabilityTime
 */

export type BookingAvailabilityTime = Readonly<
  Override<
    BookingAvailabilityTimeDto,
    {
      startTime: Date;
      endTime: Date;
    }
  >
>;

export const dtoToBookingAvailabilityTime = (dto: BookingAvailabilityTimeDto): BookingAvailabilityTime => ({
  ...dto,
  startTime: new Date(dto.startTime),
  endTime: new Date(dto.endTime),
});

export const bookingAvailabilityTimeToDto = (data: BookingAvailabilityTime): BookingAvailabilityTimeDto => ({
  ...data,
  startTime: data.startTime.toISOString(),
  endTime: data.endTime.toISOString(),
});

/**
 * BookingAvailability
 */

export type BookingAvailability = Readonly<
  Override<
    BookingAvailabilityDto,
    {
      inviteeEvents?: Event[];
      times: BookingAvailabilityTime[];
    }
  >
>;

export const dtoToBookingAvailability = (dto: BookingAvailabilityDto): BookingAvailability => ({
  ...dto,
  inviteeEvents: dto.inviteeEvents?.map(dtoToEvent),
  times: dto.times.map(dtoToBookingAvailabilityTime),
});

export const bookingAvailabilityToDto = (data: BookingAvailability): BookingAvailabilityDto => ({
  ...data,
  inviteeEvents: data.inviteeEvents?.map(eventToDto),
  times: data.times.map(bookingAvailabilityTimeToDto),
});

/**
 * SchedulingLinkMeetingRequest
 */

export type SchedulingLinkMeetingRequest = Readonly<
  Override<
    SchedulingLinkMeetingRequestDto,
    {
      start: Date;
      end: Date;
    }
  >
>;

export const dtoToSchedulingLinkMeetingRequest = (
  dto: SchedulingLinkMeetingRequestDto
): SchedulingLinkMeetingRequest => ({
  ...dto,
  start: new Date(dto.start),
  end: new Date(dto.end),
});

export const schedulingLinkMeetingRequestToDto = (
  data: SchedulingLinkMeetingRequest
): SchedulingLinkMeetingRequestDto => ({
  ...data,
  start: data.start.toISOString(),
  end: data.end.toISOString(),
});

export class SchedulingLinksDomain extends TransformDomain<SchedulingLink, SchedulingLinkDto> {
  /**
   * This domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "SchedulingLink";
  cacheKey = "scheduling_links";
  pk = "id";

  public deserialize = dtoToSchedulingLink;

  list = this.deserializeResponse(() => this.domainApi.schedulingLink.getAllLinks());

  get = this.deserializeResponse((id: string) => this.domainApi.schedulingLink.getLink(id));

  create = this.deserializeResponse((payload: CreateSchedulingLinkRequest) =>
    this.domainApi.schedulingLink.createLink(createSchedulingLinkRequestToDto(payload))
  );

  patch = this.deserializeResponse((id: string, payload: PatchSchedulingLinkRequest) =>
    this.domainApi.schedulingLink.updateLink(id, patchSchedulingLinkRequestToDto(payload))
  );

  delete = (id: string) => this.domainApi.schedulingLink.deleteLink(id);

  getBooking = (day: Date) =>
    new Promise<BookingAvailability>((resolve) => {
      setTimeout(() => resolve(dtoToBookingAvailability(mockBookingAvailability())), 2000);
    });

  requestMeeting = (request: SchedulingLinkMeetingRequest) =>
    new Promise<void>((resolve) => setTimeout(() => resolve(), 2000));
}

export const getSchedulingLinkDurationStr = (link: SchedulingLink): string => {
  const min = getDurationString(link.minDuration * MILLISECONDS_PER_MINUTE);
  const max = getDurationString(link.maxDuration * MILLISECONDS_PER_MINUTE);

  return min === max ? min : `${min} - ${max}`;
};
