/* eslint-disable no-restricted-imports */
import { setHours, setMinutes } from "date-fns";
import { DayOfWeek } from "../reclaim-api/client";
import {
  IconType as SchedulingLinkIconTypeDto,
  SchedulingLink as SchedulingLinkDto,
  SchedulingPriority as SchedulingLinkPriorityDto,
  TimePolicyType,
} from "../reclaim-api/scheduling-links/scheduling-links-client";
import { hashBoolean } from "../utils/dev";
import { BookingAvailabilityDto, BookingAvailabilityTimeDto } from "./schedulingLinks.mock.types";

export const mockBookingAvailabilityTime = (hour: number, minute: number): BookingAvailabilityTimeDto => {
  const now = new Date("2022-10-13T00:00:00.000Z");
  const start = setMinutes(setHours(now, hour), minute);
  return {
    startTime: start.toISOString(),
    endTime: setMinutes(start, 30).toISOString(),
    suggested: hashBoolean(`${hour}:${minute}`),
  };
};

export const mockBookingAvailability = (hasInviteeEvents?: boolean): BookingAvailabilityDto => ({
  times: [
    mockBookingAvailabilityTime(9, 30),
    mockBookingAvailabilityTime(10, 0),
    mockBookingAvailabilityTime(10, 30),
    mockBookingAvailabilityTime(12, 0),
    mockBookingAvailabilityTime(12, 30),
    mockBookingAvailabilityTime(1, 0),
    mockBookingAvailabilityTime(1, 30),
  ],
  // TODO: mock out events
  inviteeEvents: hasInviteeEvents ? [] : undefined,
});

export const mockSchedulingLinkDto = (): SchedulingLinkDto => ({
  id: "8d8447d7-c93d-48d1-951d-fe6365944244",
  title: "30 minute meeting",
  description:
    "A long description of my meeting link. Sit dicta perspiciatis solutall am ut saepe et id facere omnis. Qui aspernatur necess itatibus quidem et in vel.",
  enabled: true,
  organizer: {
    avatarUrl: "https://lh3.googleusercontent.com/a-/AFdZucojejfHkMEA5O6Sp_81QYNFKT1C1OV93XJzyhsH=s96-c",
    email: "reclaim.office2@gmail.com",
    name: "Michael Scott",
    userId: "ee8be6dc-7fc0-435c-9bb1-8d12c7c0b581",
  },
  timePolicyType: TimePolicyType.ONE_OFF,
  oneOffPolicy: {
    dayHours: {
      FRIDAY: {
        intervals: [
          {
            start: "09:00:00",
            end: "17:00:00",
          },
        ],
        endOfDay: "17:00:00",
      },
      MONDAY: {
        intervals: [
          {
            start: "08:00:00",
            end: "18:00:00",
          },
          {
            start: "19:00:00",
            end: "20:00:00",
          },
        ],
        endOfDay: "20:00:00",
      },
      TUESDAY: {
        intervals: [
          {
            start: "08:00:00",
            end: "18:00:00",
          },
          {
            start: "19:00:00",
            end: "20:00:00",
          },
          {
            start: "21:00:00",
            end: "22:00:00",
          },
        ],
        endOfDay: "22:00:00",
      },
      THURSDAY: {
        intervals: [
          {
            start: "09:00:00",
            end: "17:00:00",
          },
        ],
        endOfDay: "17:00:00",
      },
    },
    startOfWeek: DayOfWeek.MONDAY,
    endOfWeek: DayOfWeek.FRIDAY,
  },
  minDuration: 30,
  maxDuration: 30,
  daysIntoFuture: 14,
  startDate: undefined,
  endDate: undefined,
  priority: SchedulingLinkPriorityDto.DEFAULT,
  conferenceType: undefined,
  iconType: SchedulingLinkIconTypeDto.BOLT,
});

export const mockSchedulingLinkDtos = (): SchedulingLinkDto[] => {
  const archetype = mockSchedulingLinkDto();
  const description = "A short description of my meeting link.";

  return [
    {
      ...archetype,
      title: "Short Meeting",
      description,
      minDuration: 15,
      maxDuration: 30,
      daysIntoFuture: undefined,
      startDate: "2022-09-05T00:00:00.000-07:00",
      endDate: "2022-09-12T00:00:00.000-07:00",
      iconType: SchedulingLinkIconTypeDto.COFFEE,
    },
    archetype,
    {
      ...archetype,
      title: "Priority Meeting",
      timePolicyType: TimePolicyType.MEETING,
      oneOffPolicy: undefined,
      description,
      minDuration: 60,
      maxDuration: 60,
      iconType: SchedulingLinkIconTypeDto.LIGHT,
    },
  ];
};
